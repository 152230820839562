import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

class SearchMultiselector extends React.Component {
  static propTypes = {
    apiSearchPath: PropTypes.string.isRequired, // API endpoint to use to perform search
    objectName: PropTypes.string.isRequired, // Object name to prefix form objects
    nestedObjectName: PropTypes.string, // Object name for optional nested attributes
    selectValueField: PropTypes.string.isRequired, // Field from results to use in select value
    selectDisplayField: PropTypes.string.isRequired, // Field from results to use in select display
    searchQueryString: PropTypes.string, // Query string field to provide search term
    searchBoxLabel: PropTypes.string, // Label to display above search box
    searchBoxPlaceholder: PropTypes.string, // Placeholder for search box
    searchBoxAutoFocus: PropTypes.bool, // Whether or not to auto focus on search box
    showMatchLabel: PropTypes.bool, // Whether or not to show the number of matches above select,
    showSelectedCount: PropTypes.bool, // Whether or not to show the number of records selected
    idsCollectionName: PropTypes.string, // Custom field name to use for ids collection field
    apiTimeout: PropTypes.number, // Default API timeout in milliseconds,
    shortSearch: PropTypes.bool // Set to true to search on any number of characters
  };

  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      matchedRecords: [],
      selectedRecords: [],
      selectedResult: '',
      toRemoveRecords: []
    }
  }
  
  componentWillReceiveProps() {
    this.setState({
      searchTerm: '',
      matchedRecords: [],
      selectedRecords: [],
      selectedResult: '',
      toRemoveRecords: []
    });
  }

  handleSearchChange = (e) => {
    this.setState({ searchTerm: e.target.value });

    if (e.target.value.length > 2 || (this.props.shortSearch && e.target.value.length > 0)) {
      this.getSearchResults(e.target.value);
    } else {
      this.setState({ matchedRecords: [] });
    }
  };

  getSearchResults(searchTerm) {
    const httpClient = axios.create();
    let queryStringField = this.props.searchQueryString || 'q';
    httpClient.defaults.timeout = this.props.apiTimeout || 2000;
    httpClient.get(`${this.props.apiSearchPath}?${queryStringField}=${searchTerm}`)
      .then(this.handleSearchSuccess)
      .catch(this.handleSearchError);
  }

  handleSearchSuccess = (response) => {
    if (response.data && response.data.length > 0) {
      this.setState({
        matchedRecords: response.data,
        selectedResult: response.data[0][this.props.selectValueField]
      });
    } else {
      this.setState({
        matchedRecords: [],
        selectedResult: '' });
    }
  };

  handleSearchError = (error) => {
    console.log("There was an error while performing the search query:");
    console.dir(error);
  };

  handleSearchSelectChange = (e) => {
    this.setState({ selectedResult: parseInt(e.target.value) });
  };

  handleSelectedChange = (e) => {
    let options = e.target.options;
    let recordsToRemove = [];
    for (let i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        recordsToRemove.push(parseInt(options[i].value));
      }
    }
    this.setState({ toRemoveRecords: recordsToRemove });
  };

  handleAddClick = () => {
    if (this.state.selectedResult) {
      let matchToAdd = this.state.matchedRecords.find(o => o[this.props.selectValueField] === this.state.selectedResult);
      if (matchToAdd) {
        if (!this.state.selectedRecords.find(o => o[this.props.selectValueField] === this.state.selectedResult)) {
          const newSelectedRecords = [...this.state.selectedRecords, matchToAdd];
          this.setState({ selectedRecords: newSelectedRecords });
        }
      }
    }
  };

  handleRemoveClick = () => {
    if (this.state.toRemoveRecords.length > 0) {
      const newSelectedRecords = this.state.selectedRecords.filter(o => !this.state.toRemoveRecords.includes(o[this.props.selectValueField]));
      this.setState({ selectedRecords: newSelectedRecords, toRemoveRecords: [] });
    }
  };

  render() {
    let countLabel = '';
    let selectedLabel = '';
    let addButton = '';
    let removeButton = '';
    let searchLabel = this.props.searchBoxLabel || '\u00A0';
    let searchPlaceholder = this.props.searchBoxPlaceholder || 'Search';
    let searchAutoFocus = this.props.searchBoxAutoFocus || false;
    let idsCollectionField = this.props.idsCollectionName || 'ids';
    let nestedId = '';
    let nestedName = '';

    if (this.props.nestedObjectName) {
      nestedId = `_${this.props.nestedObjectName}`;
      nestedName = `[${this.props.nestedObjectName}]`;
    }

    if (this.props.showMatchLabel) {
      if (this.state.matchedRecords.length === 0) {
        countLabel = '0 matches';
      } else if (this.state.matchedRecords.length === 1) {
        countLabel = '1 match';
      } else {
        countLabel = `${this.state.matchedRecords.length} matches - Please select one`;
      }
    } else {
      countLabel = '\u00A0';
    }

    if (this.props.showSelectedCount) {
        selectedLabel = `${this.state.selectedRecords.length} selected`;
    } else {
      selectedLabel = '\u00A0';
    }

    if (this.state.matchedRecords.length > 0 && !this.state.selectedRecords.find(o => o[this.props.selectValueField] === this.state.selectedResult)) {
      addButton = (
        <button className="btn btn-primary btn-block"
                          onClick={this.handleAddClick}>
          Add
        </button>
      );
    } else {
      addButton = '';
    }

    if (this.state.toRemoveRecords.length > 0) {
      removeButton = (
        <button className="btn btn-danger btn-block"
                onClick={this.handleRemoveClick}>
          Remove
        </button>
      );
    } else {
      removeButton = '';
    }

    return (
      <div className="search-multiselect">
        <div className="row align-items-end">
          <div className="col-md-5">
            <div className="row">
              <div className="col-12 p-0">
                <div className="form-group">
                  <label className="control-label col-12"
                         htmlFor={`${this.props.objectName}${nestedId}_search_term`}>
                    {searchLabel}
                  </label>
                  <div className="col-12">
                    <input className="form-control form-control-sm"
                           ref={input => input && this.props.searchBoxAutoFocus && input.focus()}
                           type="search"
                           id={`${this.props.objectName}${nestedId}_search_term`}
                           name={`${this.props.objectName}${nestedName}[search_term]`}
                           placeholder={searchPlaceholder}
                           value={this.state.searchTerm}
                           onChange={this.handleSearchChange}
                           autoFocus={searchAutoFocus}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 p-0">
                <div className="form-group">
                  <label className="control-label col-12 text-secondary small"
                         htmlFor="search_results">
                    {countLabel}
                  </label>
                  <div className="col-12">
                    <select className="form-control form-control-sm"
                            name="search_results"
                            value={this.state.selectedResult}
                            onChange={this.handleSearchSelectChange}
                            id="search_results">
                      {this.state.matchedRecords.map(
                        (r) => (
                          <option key={r[this.props.selectValueField]}
                                  value={r[this.props.selectValueField]}>
                            {r[this.props.selectDisplayField]}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-2 mb-3">
            {removeButton}
            {addButton}
          </div>
          <div className="col-md-5 mb-3">
            <div className="row">
              <div className="col-12 p-0">
                <div className="form-group mb-0">
                  <label className="control-label col-12 text-secondary small"
                         htmlFor={`${this.props.objectName}${nestedId}_${idsCollectionField}`}>
                    {selectedLabel}
                  </label>
                  <div className="col-12">
                    <input type="hidden"
                           name={`${this.props.objectName}${nestedName}[${idsCollectionField}]`}
                           id={`${this.props.objectName}${nestedId}_${idsCollectionField}`}
                           value={this.state.selectedRecords.map(o => o[this.props.selectValueField])}
                    />       
                    <select multiple className="form-control form-control-sm"
                            name="idsSelectList"
                            id="idsSelectList"
                            onChange={this.handleSelectedChange}
                            style={{height: '110px'}}>
                      {this.state.selectedRecords.map(
                        (r) => (
                          <option key={r[this.props.selectValueField]}
                                  value={r[this.props.selectValueField]}>
                            {r[this.props.selectDisplayField]}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SearchMultiselector;