import React from 'react';
import PropTypes from 'prop-types';
import RoleSelector from './role_selector';

class AccountAccess extends React.Component {
  static propTypes = {
    siteId: PropTypes.number, // Include id of site for external roles assignment
    siteName: PropTypes.string, // Include name of site for external roles assignment
    objectName: PropTypes.string.isRequired, // Object name to prefix form objects
    availableRoles: PropTypes.array.isRequired, // List of all available roles
    hasAccount: PropTypes.bool.isRequired, // Indicates contact has user account already
    alreadyHadAccess: PropTypes.bool.isRequired, // Indicates user already had access to site
    statsWarning: PropTypes.bool.isRequired, // Whether user should receive warning about reports becoming late
    statsEscalation: PropTypes.bool.isRequired, // Whether user should receive escalation email about reports late
    monthlyReminder: PropTypes.bool.isRequired, // Where user should receive monthly reminder to submit reports
    tempPassword: PropTypes.string, // Optional temp password
    selectedRoles: PropTypes.oneOfType(
      [PropTypes.array, PropTypes.arrayOf(PropTypes.number)]
    ).isRequired // Assigned roles
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedRoles: this.props.selectedRoles,
      grantAccess: this.props.grantAccess || false,
      tempPassword: this.props.tempPassword,
      sendEmail: !this.props.alreadyHadAccess,
      statsWarning: this.props.statsWarning,
      statsEscalation: this.props.statsEscalation,
      monthlyReminder: this.props.monthlyReminder
    }
  }

  handleCheckboxClick = (e) => {
    this.setState({ grantAccess: e.target.checked });

    // Clear any assigned roles if the access checkbox is unchecked
    if (!e.target.checked) {
      this.setState({ selectedRoles: [] });
    }
  };

  handleEmailCheckboxClick = (e) => {
    this.setState({ sendEmail: e.target.checked });
  };

  handleStatsWarningCheckboxClick = (e) => {
    this.setState({ statsWarning: e.target.checked });
  };

  handleStatsEscalationCheckboxClick = (e) => {
    this.setState({ statsEscalation: e.target.checked });
  };

  handleMonthlyReminderCheckboxClick = (e) => {
    this.setState({ monthlyReminder: e.target.checked });
  };

  handlePasswordChange = (e) => {
    this.setState({ tempPassword: e.target.value });
  };

  render () {
    let roleSelector = null;
    let tempPassword = null;
    let sendEmailCheckbox = null;
    let emailNotifications = null;

    if (this.state.grantAccess) {
      roleSelector = (
        <RoleSelector siteId={this.props.siteId}
                      siteName={this.props.siteName}
                      objectName={this.props.objectName}
                      availableRoles={this.props.availableRoles}
                      selectedRoles={this.state.selectedRoles} />
      );
      emailNotifications = (
        <div className="email-notification">
          <h6 className="mt-4 mb-2">Email notifications</h6>
          <div className="row mt-2">
            <div className="col-12">
              <div className="checkbox m-0 mr-auto">
                <input type="checkbox"
                       checked={this.state.statsWarning}
                       onChange={this.handleStatsWarningCheckboxClick}
                       name={`${this.props.objectName}[stats_warning]`}
                       id="stats_warning" />
                <label className="checkbox m-0 mr-auto"
                       htmlFor="stats_warning">
                  Email when meals stats report first becomes overdue
                </label>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-12">
              <div className="checkbox m-0 mr-auto">
                <input type="checkbox"
                       checked={this.state.statsEscalation}
                       onChange={this.handleStatsEscalationCheckboxClick}
                       name={`${this.props.objectName}[stats_escalation]`}
                       id="stats_escalation" />
                <label className="checkbox m-0 mr-auto"
                       htmlFor="stats_escalation">
                  Email second warning when meals stats report about to be locked
                </label>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-12">
              <div className="checkbox m-0 mr-auto">
                <input type="checkbox"
                       checked={this.state.monthlyReminder}
                       onChange={this.handleMonthlyReminderCheckboxClick}
                       name={`${this.props.objectName}[monthly_reminder]`}
                       id="monthly_reminder" />
                <label className="checkbox m-0 mr-auto"
                       htmlFor="monthly_reminder">
                  Email monthly reports reminder 5 days before end of month
                </label>
              </div>
            </div>
          </div>
        </div>
      );
      if (this.props.hasAccount) {
        tempPassword = (
          <div className="temp-password mt-3 mb-3">
            <div className="text-secondary">
              <em>User already has sign-in credentials.</em><br/>Please select the permissions to assign for this site.
            </div>
          </div>
        );
      } else {
        tempPassword = (
          <div className="temp-password mt-3">
            <div className="form-group row ">
              <label className="control-label col-12"
                     htmlFor="temp_password">Password</label>
              <div className="col-12">
                <input className="form-control form-control-sm"
                       type="text"
                       value={this.state.tempPassword}
                       onChange={this.handlePasswordChange}
                       name={`${this.props.objectName}[temp_password]`}
                       id="temp_password"
                       required={true} />
                 <small>If checked below, user will receive a welcome email with sign-in instructions including this password.</small>
              </div>
            </div>
          </div>
        )
      }
      if (!this.props.alreadyHadAccess) {
        sendEmailCheckbox = (
          <div className="row">
            <div className="col-12">
              <div className="checkbox m-0 mr-auto">
                <input type="checkbox"
                       checked={this.state.sendEmail}
                       onChange={this.handleEmailCheckboxClick}
                       name={`${this.props.objectName}[send_email]`}
                       id="send_email" />
                <label className="checkbox m-0 mr-auto"
                       htmlFor="send_email">
                  Send welcome email
                </label>
              </div>
            </div>
          </div>
        );
      }
    } else {
      roleSelector = null;
      tempPassword = null;
    }

    return (
      <div className="account-access mt-4">
        <div className="row">
          <div className="col-12">
            <div className="checkbox m-0 mr-auto">
              <input type="checkbox"
                     checked={this.state.grantAccess}
                     onChange={this.handleCheckboxClick}
                     name={`${this.props.objectName}[grant_access]`}
                     id="grant_access" />
              <label className="checkbox m-0 mr-auto"
                     htmlFor="grant_access">
                Allow to sign in and access this site
              </label>
            </div>
          </div>
        </div>
        {tempPassword}
        {sendEmailCheckbox}
        {roleSelector}
        {emailNotifications}
      </div>
    );
  }

}

export default AccountAccess;


