import React from 'react';
import PropTypes from 'prop-types';

class DateRangeSelector extends React.Component {
  static propTypes = {
    fieldnamePrefix: PropTypes.string.isRequired, // Value to prefix fieldnames for Rails forms
    nestedObjectName: PropTypes.string, // Object name for optional nested attributes
    minDateString: PropTypes.string, // Date string to limit start selection
    maxDateString: PropTypes.string, // Date string to limit end selection
    startDateField: PropTypes.string, // Used to override the default start date field name
    endDateField: PropTypes.string, // Used to override the default end date field name
    singleOptionLabel: PropTypes.string, // Override the default label for the single date option
    rangeOptionLabel: PropTypes.string, // Override the default label for date range options
    inputGroupClass: PropTypes.string, // Override the default input group classes
    formControlClass: PropTypes.string // Override the default form control classes
  };

  constructor(props) {
    super(props);
    this.state = {
      dateRange: false,
      endMinDate: ''
    }
  }

  handleOptionClick = (e) => {
    this.setState({ dateRange: e.target.value === 'range' });
  };

  render() {
    let startField = this.props.startDateField || 'start_date';
    let endField = this.props.endDateField || 'end_date';
    let singleLabel = this.props.singleOptionLabel || 'Event for a single date';
    let rangeLabel = this.props.rangeOptionLabel || 'Event for a range of dates';
    let inputGroupClass = this.props.inputGroupClass || 'input-group-sm';
    let formControlClass = this.props.formControlClass || 'form-control-sm';
    let nestedId = '';
    let nestedName = '';

    if (this.props.nestedObjectName) {
      nestedId = `_${this.props.nestedObjectName}`;
      nestedName = `[${this.props.nestedObjectName}]`;
    }

    return (
      <div>
        <div className="row">
          <div className="col">
            <div className="radio radio-info form-check form-check-inline">
              <input type="radio"
                     id="dateSingleOption"
                     value="single"
                     name={`${this.props.fieldnamePrefix}${nestedName}[date_option]`}
                     onChange={this.handleOptionClick}
                     checked={!this.state.dateRange} />
              <label htmlFor="dateSingleOption">{singleLabel}</label>
            </div>
            <div className="radio radio-info form-check form-check-inline">
              <input type="radio"
                     id="dateRangeOption"
                     value="range"
                     name={`${this.props.fieldnamePrefix}${nestedName}[date_option]`}
                     onChange={this.handleOptionClick}
                     checked={this.state.dateRange} />
              <label htmlFor="dateRangeOption">{rangeLabel}</label>
            </div>
          </div>
        </div>
        <hr/>
        <div className="row">
          <div className="col-md-6">
            <div className={`form-group row ${inputGroupClass}`}>
              <label className="control-label col-12"
                     htmlFor={`${this.props.fieldnamePrefix}${nestedId}_${startField}`}
                     >
                {this.state.dateRange ? 'Start date' : 'Date'}
              </label>
              <div className="col-12">
                <input type="text"
                       id={`${this.props.fieldnamePrefix}${nestedId}_${startField}`}
                       className={`form-control datepicker ${formControlClass}`}
                       name={`${this.props.fieldnamePrefix}${nestedName}[${startField}]`}
                       data-min-date={this.props.minDateString}
                       data-max-date={this.props.maxDateString}
                       placeholder="mm/dd/yyyy" />
              </div>
            </div>
          </div>
          <div className="col-md-6" style={{visibility: this.state.dateRange ? 'visible' : 'hidden'}}>
            <div className={`form-group row ${inputGroupClass}`}>
              <label className="control-label col-12"
                     htmlFor={`${this.props.fieldnamePrefix}${nestedId}_${endField}`}>End date</label>
              <div className="col-12">
                <input type="text"
                       id={`${this.props.fieldnamePrefix}${nestedId}_${endField}`}
                       className={`form-control datepicker ${formControlClass}`}
                       name={`${this.props.fieldnamePrefix}${nestedName}[${endField}]`}
                       data-min-date={this.props.minDateString}
                       data-max-date={this.props.maxDateString}
                       placeholder="mm/dd/yyyy" />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default DateRangeSelector;