import React from 'react';
import PropTypes from 'prop-types';

class FinancialResponsibility extends React.Component {
  static propTypes = {
    objectName: PropTypes.string.isRequired, // Object name for prefixing form fields
    responsibleParty: PropTypes.string, // Current responsibility party
    pricePerMeal: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // Price per meal
    numberToCredit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // Current number of meals credited
    dollarsToCredit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // Current total dollar value of credit
    mealsAffected: PropTypes.oneOfType([PropTypes.number, PropTypes.string]) // Number of meals reported affected
  };

  constructor(props) {
    super(props);
    let initialPricePer = '';
    let initialDollars = '';
    let initialNumber = '';

    if (this.props.pricePerMeal && this.props.pricePerMeal > 0) {
      initialPricePer = parseFloat(Math.round(this.props.pricePerMeal * 100) / 100).toFixed(2);
    }

    if (this.props.dollarsToCredit && this.props.dollarsToCredit > 0) {
      initialDollars = parseFloat(Math.round(this.props.dollarsToCredit * 100) / 100).toFixed(2);
    } else {
      if (this.props.mealsAffected && this.props.mealsAffected > 0 && initialPricePer && initialPricePer > 0) {
        initialDollars = parseFloat(Math.round(this.props.mealsAffected * initialPricePer * 100) / 100).toFixed(2);
      }
    }

    if (this.props.numberToCredit) {
      initialNumber = this.props.numberToCredit;
    } else if (!this.props.dollarsToCredit) {
      initialNumber = this.props.numberToCredit || this.props.mealsAffected || '';
    }

    this.state = {
      responsibleParty: this.props.responsibleParty || '',
      pricePerMeal: initialPricePer,
      numberToCredit: initialNumber,
      dollarsToCredit: initialDollars
    }
  }

  handlePartyChange = (e) => {
    let newNumberToCredit = '';
    let newDollarsToCredit = '';
    if (!(e.target.value === 'vendor')) {
      this.setState({
        responsibleParty: e.target.value,
        numberToCredit: newNumberToCredit,
        dollarsToCredit: newDollarsToCredit });
    } else {
      this.setState({ responsibleParty: e.target.value });
    }
  };

  handlePricePerChange = (e) => {
    let newPricePer = e.target.value;
    let newTotalCredit = '';
    if (newPricePer && newPricePer > 0 &&
      this.state.numberToCredit && this.state.numberToCredit > 0) {
      newTotalCredit = parseFloat(Math.round((this.state.numberToCredit * newPricePer) * 100) / 100).toFixed(2);
    }
    this.setState({ pricePerMeal: e.target.value, dollarsToCredit: newTotalCredit });
  };

  handleNumberCreditChange = (e) => {
    let newNumberToCredit = e.target.value;
    let newTotalCredit = '';
    if (newNumberToCredit && newNumberToCredit > 0 &&
      this.state.pricePerMeal && this.state.pricePerMeal > 0) {
      newTotalCredit = parseFloat(Math.round((newNumberToCredit * this.state.pricePerMeal) * 100) / 100).toFixed(2);
    }
    this.setState({ numberToCredit: e.target.value, dollarsToCredit: newTotalCredit });
  };

  handleDollarCreditChange = (e) => {
    this.setState({ dollarsToCredit: e.target.value, numberToCredit: '' });
  };

  render() {
    let pricePerField = '';
    let creditNumberField = '';
    let creditDollarsField = '';

    if (this.state.responsibleParty === 'vendor') {
      pricePerField = (
        <div className="input-group input-group-sm">
          <label className="control-label col-12 "
                 htmlFor="price-per-meal">
            Price per meal
          </label>
          <span className="input-group-prepend ml-3">
            <div className="input-group-text">$</div>
          </span>
          <input type="number"
                 step="0.01"
                 id="price-per-meal"
                 onChange={this.handlePricePerChange}
                 value={this.state.pricePerMeal}
                 className="form-control form-control-sm" />
        </div>
      );
    }

    if (this.state.responsibleParty === 'vendor') {
      creditNumberField = (
        <div className="input-group">
          <label className="control-label col-12 pl-0"
                 htmlFor={`${this.props.objectName}_vendor_credit_meals`}>
            Vendor credit # meals
          </label>
          <input type="number"
                 id={`${this.props.objectName}_vendor_credit_meals`}
                 name={`${this.props.objectName}[vendor_credit_meals]`}
                 onChange={this.handleNumberCreditChange}
                 value={this.state.numberToCredit}
                 className="form-control form-control-sm" />
        </div>
      );

      creditDollarsField = (
        <div className="input-group input-group-sm">
          <label className="control-label col-12 "
                 htmlFor={`${this.props.objectName}_vendor_credit_dollars`}>
            Vendor credit total
          </label>
          <span className="input-group-prepend ml-3">
            <div className="input-group-text">$</div>
          </span>
          <input type="number"
                 step="0.01"
                 id={`${this.props.objectName}_vendor_credit_dollars`}
                 name={`${this.props.objectName}[vendor_credit_dollars]`}
                 onChange={this.handleDollarCreditChange}
                 value={this.state.dollarsToCredit}
                 className="form-control form-control-sm" />
        </div>
      );
    } else {
      creditNumberField = (
        <input type="hidden"
               value=""
               name={`${this.props.objectName}[vendor_credit_meals]`}
               id={`${this.props.objectName}_vendor_credit_meals`} />
      );

      creditDollarsField = (
        <input type="hidden"
               value=""
               name={`${this.props.objectName}[vendor_credit_dollars]`}
               id={`${this.props.objectName}_vendor_credit_dollars`} />
      );
    }

    return (
      <div className="financial-responsibility mt-4">
        <hr />
        <h6>Financial Responsibility</h6>
        <div className="row">
          <div className="col-md-6 pl-0">
            <div className="form-group">
              <label className="control-label col-12 "
                     htmlFor={`${this.props.objectName}_financial_responsibility`}>
                Responsible party
              </label>
              <div className="col-12">
                <select className="form-control form-control-sm"
                        name={`${this.props.objectName}[financial_responsibility]`}
                        id={`${this.props.objectName}_financial_responsibility`}
                        value={this.state.responsibleParty}
                        onChange={this.handlePartyChange}
                >
                  <option value=''></option>
                  <option key="1"
                          value="leap">
                    leap
                  </option>
                  <option key="2"
                          value="site">
                    Site
                  </option>
                  <option key="3"
                          value="vendor">
                    Vendor
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            {pricePerField}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            {creditNumberField}
          </div>
          <div className="col-md-6">
            {creditDollarsField}
          </div>
        </div>
      </div>
    );
  }
}

export default FinancialResponsibility;