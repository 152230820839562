import React from 'react';
import PropTypes from 'prop-types';
import SubscriptionDay from './subscription_day';

class SubscriptionTable extends React.Component {
  static propTypes = {
    currentDays: PropTypes.oneOfType(
      [PropTypes.object, PropTypes.arrayOf(PropTypes.object)]
    ).isRequired // Current list of assigned subscription days (or empty object)
  };

  constructor(props) {
    super(props);
    this.state = {
      currentDays: this.props.currentDays || [],
      lastChanged: {
        mealCount: '',
        startTime: '',
        deliveryTime: '',
        endTime: ''
      }
    }
  }

  componentDidMount() {
    if (this.state.currentDays.length > 0) {
      let lastDay = this.state.currentDays[this.state.currentDays.length - 1];
      this.setState({lastChanged:
          {
            mealCount: lastDay.meal_count || '',
            startTime: lastDay.start_time ? moment(lastDay.start_time).format('HH:mm') : '',
            deliveryTime: lastDay.delivery_time ? moment(lastDay.delivery_time).format('HH:mm') : '' ,
            endTime: lastDay.end_time ? moment(lastDay.end_time).format('HH:mm') : ''}
      });
    }
  }
  getDayElementFor(dayCollection, dayName) {
    let filteredArray = dayCollection.filter((item) => {
      return item.day_of_week === dayName;
    });

    
    return filteredArray[0];
  }

  onLastChanged = (e) => {
    const fields = Object.assign({}, this.state.lastChanged);
    const fieldName = e.target.getAttribute("data-field-name");
    fields[fieldName] = e.target.value;
    this.setState({lastChanged: fields});
  };

  render() {
    let dayCollection = null;
    let allDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
    if (this.state.currentDays.length > 0) {
      dayCollection = allDays.map((day) => {
          let matchingRecord = this.getDayElementFor(this.state.currentDays, day);
          if (matchingRecord) {
            return (<SubscriptionDay key={day}
                                     isActive={true}
                                     dayOfWeek={day}
                                     mealCount={matchingRecord.meal_count}
                                     startTime={matchingRecord.start_time}
                                     deliveryTime={matchingRecord.delivery_time}
                                     endTime={matchingRecord.end_time}
                                     lastChanged={this.state.lastChanged}
                                     onDataChange={this.onLastChanged} />);
          } else {
            return (<SubscriptionDay key={day}
                                     dayOfWeek={day}
                                     lastChanged={this.state.lastChanged}
                                     onDataChange={this.onLastChanged} />);
          }
      });
    } else {
      dayCollection = allDays.map((day) =>
        <SubscriptionDay key={day}
                         dayOfWeek={day}
                         lastChanged={this.state.lastChanged}
                         onDataChange={this.onLastChanged} />
      )
    }

    return (
      <div className="table-responsive mt-0">
        <table className="table table-sm mt-3">
          <thead>
          <tr>
            <th>Day of Week</th>
            <th>Meal Count</th>
            <th>Delivery Time</th>
            <th>Start Time</th>
            <th>End Time</th>
          </tr>
          </thead>
          <tbody>
            { dayCollection }
          </tbody>
        </table>
      </div>
    );
  }
}

export default SubscriptionTable;