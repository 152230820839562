import ReactOnRails from 'react-on-rails';

import RoleSelector from '../bundles/users/role_selector';
import AccountAccess from '../bundles/users/account_access';
import SubscriptionTable from '../bundles/subscriptions/subscription_table';
import SubscriptionDay from '../bundles/subscriptions/subscription_day';
import DateRangeSelector from '../bundles/shared/date_range_selector';
import VendorSelector from '../bundles/meals/vendor_selector';
import SearchSelector from '../bundles/shared/search_selector';
import WorkingStates from '../bundles/log_entries/working_states';
import FinancialResponsibility from '../bundles/log_entries/financial_responsibility';
import CatWorkingStates from '../bundles/corrective_actions/cat_working_states';
import SearchMultiselector from '../bundles/shared/search_multiselector';
import FilterOptions from '../bundles/shared/filter_options';

ReactOnRails.register({
  RoleSelector,
  AccountAccess,
  SubscriptionTable,
  SubscriptionDay,
  DateRangeSelector,
  VendorSelector,
  SearchSelector,
  WorkingStates,
  FinancialResponsibility,
  CatWorkingStates,
  SearchMultiselector,
  FilterOptions
});
