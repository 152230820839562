import React from 'react';
import PropTypes from 'prop-types';

class VendorSelector extends React.Component {
  static propTypes = {
    mealId: PropTypes.number, // Include id of meal for restricted vendors
    objectName: PropTypes.string.isRequired, // Object name to prefix form objects
    availableVendors: PropTypes.array.isRequired, // List of all available vendors
    restrictVendors: PropTypes.bool.isRequired, // Whether or not to restrict vendors
    selectedVendors: PropTypes.oneOfType(
      [PropTypes.array, PropTypes.arrayOf(PropTypes.object)]
    ).isRequired // Selected vendors
  };

  constructor(props) {
    super(props);
    let initVendorPrices = {};
    this.props.selectedVendors.forEach((o) => {
      initVendorPrices[o.vendor] = parseFloat(Math.round(o.price * 100) / 100).toFixed(2);
    });
    this.state = {
      selectedVendors: this.props.selectedVendors.map(o => o.vendor),
      restrictVendors: this.props.restrictVendors,
      vendorPrices: initVendorPrices
    }
  }

  handleCheckboxClick = (e) => {
    this.setState({ restrictVendors: e.target.checked });

    // Clear any assigned vendors if the checkbox is unchecked
    if (!e.target.checked) {
      this.setState({ selectedVendors: [] });
    }
  };

  handlePriceChange = (e) => {
    let newVendorPrices = Object.assign({}, this.state.vendorPrices);
    newVendorPrices[e.target.dataset.vendor] = e.target.value;
    this.setState({ vendorPrices: newVendorPrices });
  };

  updateSelections = (e) => {
    let newSelectedVendors = this.state.selectedVendors.slice();
    if (newSelectedVendors.includes(parseInt(e.target.value))) {
      // In the array already so remove it
      newSelectedVendors.splice(newSelectedVendors.indexOf(parseInt(e.target.value)), 1);
    } else {
      // Not in the array so add it
      newSelectedVendors.push(parseInt(e.target.value));
    }

    let newVendorPrices = Object.assign({}, this.state.vendorPrices);
    if (!e.target.checked) {
      newVendorPrices[e.target.value] = '';
    }
    this.setState({ selectedVendors: newSelectedVendors, vendorPrices: newVendorPrices });
  };

  render() {
    let mealField = null;
    let vendorTable = '';

    if (this.props.mealId) {
      mealField = (
        <input type="hidden" id="vendor_meal_id" name="vendor_meal_id" value={this.props.mealId} />
      );
    }

    if (this.state.restrictVendors) {
      vendorTable = (
        <table className="table table-sm mt-3 mb-3">
          <thead>
          <tr>
            <th>
              <b>Restrict meal type to these vendors:</b><span className="ml-1">{`(${this.state.selectedVendors.length})`}</span>
            </th>
            <th>Price per meal</th>
          </tr>
          </thead>
          <tbody>
          { this.props.availableVendors.map(vendor => (
            <tr key={vendor.id}>
              <td>
                <label className="checkbox-inline mb-0">
                  <input type="checkbox"
                         value={vendor.id}
                         className="mr-3"
                         checked={this.state.selectedVendors.includes(vendor.id)}
                         onChange={this.updateSelections}
                  />
                  { vendor.name }
                </label>
                {
                  (this.state.selectedVendors.includes(vendor.id)) ?
                    <input type="hidden"
                           name={`${this.props.objectName}[vendor_ids][]`}
                           id={`include_vendors_${vendor.id}`}
                           value={[vendor.id, this.state.vendorPrices[vendor.id]]} />
                    : ""
                }
              </td>
              <td>
                <div className="input-group">
                  <span className="input-group-prepend">
                    <div className="input-group-text">$</div>
                  </span>
                  <input type="number"
                         step="0.01"
                         data-vendor={vendor.id}
                         onChange={this.handlePriceChange}
                         value={this.state.vendorPrices[vendor.id]}
                         disabled={!this.state.selectedVendors.includes(vendor.id)}
                         className="form-control form-control-sm" />
                </div>
              </td>
            </tr>
          ))}
          </tbody>
        </table>
      );
    }

    return (
      <div>
        <div className="role-list mt-4">
          <div className="row">
            <div className="col-12">
              <div className="checkbox m-0 mr-auto">
                <input name={`${this.props.objectName}[restrict_vendors]`} type="hidden" value="0" />
                <input type="checkbox"
                       checked={this.state.restrictVendors}
                       onChange={this.handleCheckboxClick}
                       name={`${this.props.objectName}[restrict_vendors]`}
                       id="restrict_vendors" />
                <label className="checkbox m-0 mr-auto"
                       htmlFor="restrict_vendors">
                  Limit this meal type to specific vendors
                </label>
              </div>
            </div>
          </div>
          { vendorTable }
          { mealField }
        </div>
      </div>
    );
  }
}

export default VendorSelector;