import React from 'react';
import PropTypes from 'prop-types';

class CatWorkingStates extends React.Component {
  static propTypes = {
    objectName: PropTypes.string.isRequired, // Object name to prefix form objects
    status: PropTypes.string.isRequired, // Existing log entry status
    workingNotes: PropTypes.string, // Existing working notes
    resolution: PropTypes.string // Existing resolution
  };

  constructor(props) {
    super(props);
    this.state = {
      isWorking: this.props.status === 'working',
      isResolved: this.props.status === 'resolved',
      workingNotes: this.props.workingNotes || '',
      resolution: this.props.resolution || '',
      status: this.props.status
    };
  }

  handleWorkingClick = (e) => {
    this.setState({
      isWorking: e.target.checked,
      isResolved: false,
      status: e.target.checked ? 'working' : this.props.status
    }, () => {
      let workingNotes = document.getElementById(`${this.props.objectName}_working_notes`);
      if (workingNotes) { workingNotes.focus(); }
    });
  };

  handleResolvedClick = (e) => {
    this.setState({
      isResolved: e.target.checked,
      isWorking: false,
      status: e.target.checked ? 'resolved' : this.props.status
    }, () => {
      let resolution = document.getElementById(`${this.props.objectName}_resolution`);
      if (resolution) { resolution.focus(); }
    });
  };

  handleNotesChange = (e) => {
    this.setState({ workingNotes: e.target.value });
  };

  handleResolutionChange = (e) => {
    this.setState({ resolution: e.target.value });
  };

  render() {
    let workingNotes = '';
    let resolution = '';

    if (this.state.isWorking ||
      (this.state.workingNotes && this.state.workingNotes.length > 0)) {
      workingNotes = (
        <div className="form-group row ">
          <label className="control-label col-12"
                 htmlFor={`${this.props.objectName}_working_notes`}>
            <h6>Working Notes</h6>
          </label>
          <div className="col-12">
            <textarea className="form-control form-control-sm"
                      rows="8"
                      name={`${this.props.objectName}[working_notes]`}
                      id={`${this.props.objectName}_working_notes`}
                      value={this.state.workingNotes}
                      onChange={this.handleNotesChange}
                      spellCheck="true" />
          </div>
        </div>
      );
    }

    if (this.state.isResolved ||
      (this.state.resolution && this.state.resolution.length > 0)) {
      resolution = (
        <div className="form-group row ">
          <label className="control-label col-12"
                 htmlFor={`${this.props.objectName}_resolution`}>
            <h6>Resolution</h6>
          </label>
          <div className="col-12">
            <textarea className="form-control form-control-sm"
                      rows="8"
                      name={`${this.props.objectName}[resolution]`}
                      id={`${this.props.objectName}_resolution`}
                      value={this.state.resolution}
                      onChange={this.handleResolutionChange}
                      spellCheck="true" />
          </div>
        </div>
      );
    }

    return (
      <div className="log-working-states mt-4">
        <div className="row">
          <div className="col-md-3">
            <div className="checkbox m-0 mr-auto">
              <input type="checkbox"
                     checked={this.state.isWorking}
                     onChange={this.handleWorkingClick}
                     id="is-working"
              />
              <label className="checkbox m-0 mr-auto"
                     htmlFor="is-working">
                Mark as working
              </label>
            </div>
          </div>
          <div className="col-md-9">
            {workingNotes}
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-3">
            <div className="checkbox m-0 mr-auto">
              <input type="checkbox"
                     checked={this.state.isResolved}
                     onChange={this.handleResolvedClick}
                     id="is-resolved"
              />
              <label className="checkbox m-0 mr-auto"
                     htmlFor="is-resolved">
                Mark as resolved
              </label>
            </div>
          </div>
          <div className="col-md-9">
            {resolution}
          </div>
          <input type="hidden"
                 value={this.state.status}
                 name={`${this.props.objectName}[status]`}
                 id={`${this.props.objectName}_status`} />
        </div>
      </div>
    );
  }
}

export default CatWorkingStates;