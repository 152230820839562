import React from 'react';
import PropTypes from 'prop-types';

class RoleSelector extends React.Component {
  static propTypes = {
    siteId: PropTypes.number, // Include id of site for external roles assignment
    siteName: PropTypes.string, // Include name of site for external roles assignment
    objectName: PropTypes.string.isRequired, // Object name to prefix form objects
    availableRoles: PropTypes.array.isRequired, // List of all available roles
    selectedRoles: PropTypes.oneOfType(
      [PropTypes.array, PropTypes.arrayOf(PropTypes.number)]
    ).isRequired // Assigned roles
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedRoles: this.props.selectedRoles,
      disabledRoles: []
    }
  }

  componentDidMount() {
    this.state.selectedRoles.forEach( (roleId) => {
      this.disableLowerPriorityRolesFor(this.state.selectedRoles, true);
    });
  }

  disableLowerPriorityRolesFor(roleId, addingRole) {
    // Each role has a level and feature
    // Features group related roles level indicate how permissive the individual role
    // is, with lower numerical levels being more permission.
    // Therefore, it doesn't make sense to add both a high and low permissive role,
    // so this function disables lower-permission roles for a given feature when
    // a higher-level role is selected

    let idsToChange = [];

    // Can handle array or single value
    if (Array.isArray(roleId)) {
      roleId.forEach ( (id) => {
        idsToChange = idsToChange.concat(this.lowerPriorityRolesFor(id));
      })
    } else {
      idsToChange = this.lowerPriorityRolesFor(roleId);
    }

    // If the selected Role is active, disable the other roles, otherwise enable them
    let newDisabledRoles = this.state.disabledRoles.slice();

    if (addingRole) {
      // The passed in role is selected, so disable the ids to change by adding to disable list
      newDisabledRoles = newDisabledRoles.concat(idsToChange);
    } else {
      // The passed in role is not selected, so enable the ids to change by removing from disable list
      newDisabledRoles = newDisabledRoles.filter( r => !idsToChange.includes(r) );
    }

    this.setState({
      disabledRoles: newDisabledRoles
    });
  };

  lowerPriorityRolesFor(roleId) {
    // For the passed in role id, returns an array of other role ids
    // sharing the same feature name but with lower priority role levels

    // First get a list of other available roles sharing the selected role's feature
    let selectedRole = this.props.availableRoles.find( r => r.id === roleId );
    let featureRoles = this.props.availableRoles.filter( r => r.feature === selectedRole.feature && r.id !== roleId );
    let lowerLevelRoles = [];

    // Now get a list of role ids with the same feature and with lower priority
    // permission levels (level numbers numerically higher than the selected role's level)
    if (selectedRole.feature === '*') {
      // If the application admin role is passed in, ALL other roles are lower
      lowerLevelRoles = this.props.availableRoles.filter( r => r.feature !== '*' );
    } else {
      lowerLevelRoles = featureRoles.filter( r => r.level > selectedRole.level );
    }

    //Return the result
    return lowerLevelRoles.map(r => r.id);
  }

  updateSelections = (e) => {
    let newSelectedRoles = this.state.selectedRoles.slice();
    let lowerLevelRelatedIds = this.lowerPriorityRolesFor(parseInt(e.target.value));
    let addingRole = false;
    if (newSelectedRoles.includes(parseInt(e.target.value))) {
      // In the array already so remove it
      newSelectedRoles.splice(newSelectedRoles.indexOf(parseInt(e.target.value)), 1);
    } else {
      // Not in the array so add it
      addingRole = true;
      newSelectedRoles.push(parseInt(e.target.value));

      // Also make sure none of the related lower level roles are selected
      newSelectedRoles = newSelectedRoles.filter( r => !lowerLevelRelatedIds.includes(r) );
    }

    this.setState({ selectedRoles: newSelectedRoles });
    this.disableLowerPriorityRolesFor(parseInt(e.target.value), addingRole);
  };

  render() {
    let siteField = null;
    let siteHeader = null;

    if (this.props.siteId) {
      siteField = (
        <input type="hidden" id="role_site_id" name="role_site_id" value={this.props.siteId} />
      );
    }

    if (this.props.siteId && this.props.siteName) {
      siteHeader = (
        <h6>Permissions for: {this.props.siteName}</h6>
      );
    }

    return (
      <div>
        <div className="role-list mt-4">
          {siteHeader}
          <table className="table table-striped table-sm table-hover mt-3 mb-3">
            <thead>
            <tr>
              <th>
                <b>Assigned Permissions</b><span className="ml-1">{`(${this.state.selectedRoles.length})`}</span>
              </th>
              <th>
                Description
              </th>
            </tr>
            </thead>
            <tbody>
            { this.props.availableRoles.map(role => (
              <tr key={role.id} className={this.state.disabledRoles.includes(role.id) ? 'text-muted' : ''} >
                <td>
                    <label className="checkbox-inline mb-0">
                      <input type="checkbox"
                             value={role.id}
                             className="mr-3"
                             checked={this.state.selectedRoles.includes(role.id)}
                             disabled={this.state.disabledRoles.includes(role.id)}
                             onChange={this.updateSelections}
                      />
                      { role.name }
                    </label>
                    {
                      (this.state.selectedRoles.includes(role.id)) ?
                        <input type="hidden"
                               name={`${this.props.objectName}[role_ids][]`}
                               id={`include_roles_${role.id}`}
                               value={role.id} />
                        : ""
                    }
                </td>
                <td>
                  { role.description }
                </td>
              </tr>
            ))}
            </tbody>
          </table>
          { siteField }
        </div>
      </div>
    );
  }
}

export default RoleSelector;