import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

class SearchSelector extends React.Component {
  static propTypes = {
    apiSearchPath: PropTypes.string.isRequired, // API endpoint to use to perform search
    objectName: PropTypes.string.isRequired, // Object name to prefix form objects
    selectValueField: PropTypes.string.isRequired, // Field from results to use in select value
    selectDisplayField: PropTypes.string.isRequired, // Field from results to use in select display
    searchQueryString: PropTypes.string, // Query string field to provide search term
    searchBoxLabel: PropTypes.string, // Label to display above search box
    searchBoxPlaceholder: PropTypes.string, // Placeholder for search box
    searchBoxAutoFocus: PropTypes.bool, // Whether or not to auto focus on search box
    showMatchLabel: PropTypes.bool, // Whether or not to show the number of matches above select,
    selectName: PropTypes.string, // Custom field name to use for select field
    apiTimeout: PropTypes.number // Default API timeout in milliseconds
  };

  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      matchedRecords: []
    }
  }

  getSearchResults(searchTerm) {
    const httpClient = axios.create();
    let queryStringField = this.props.searchQueryString || 'q';
    httpClient.defaults.timeout = this.props.apiTimeout || 2000;
    httpClient.get(`${this.props.apiSearchPath}?${queryStringField}=${searchTerm}`)
      .then(this.handleSearchSuccess)
      .catch(this.handleSearchError);
  }

  handleSearchChange = (e) => {
    this.setState({ searchTerm: e.target.value });
    
    if (e.target.value.length > 2) {
      this.getSearchResults(e.target.value);
    } else {
      this.setState({ matchedRecords: [] });
    }
  };

  handleSearchSuccess = (response) => {
    if (response.data && response.data.length > 0) {
      this.setState({ matchedRecords: response.data });
    } else {
      this.setState({ matchedRecords: [] });
    }
  };

  handleSearchError = (error) => {
    console.log("There was an error while performing the search query:");
    console.dir(error);
  };

  render() {
    let countLabel = '';
    let searchLabel = this.props.searchBoxLabel || '\u00A0';
    let searchPlaceholder = this.props.searchBoxPlaceholder || 'Search';
    let searchAutoFocus = this.props.searchBoxAutoFocus || false;
    let searchResultField = this.props.selectName || 'search_result';

    if (this.props.showMatchLabel) {
      if (this.state.matchedRecords.length === 0) {
        countLabel = '0 matches';
      } else if (this.state.matchedRecords.length === 1) {
        countLabel = '1 match';
      } else {
        countLabel = `${this.state.matchedRecords.length} matches - Please select one`;
      }
    } else {
      countLabel = '\u00A0';
    }
    
    return (
      <div className="search-select">
        <div className="row">
          <div className="col-md-4">
            <div className="form-group">
              <label className="control-label col-12"
                     htmlFor={`${this.props.objectName}_search_term`}>
                {searchLabel}
              </label>
              <div className="col-12">
                <input className="form-control form-control-sm"
                       type="search"
                       id={`${this.props.objectName}_search_term`}
                       name={`${this.props.objectName}[search_term]`}
                       placeholder={searchPlaceholder}
                       value={this.state.searchTerm}
                       onChange={this.handleSearchChange}
                       autoFocus={searchAutoFocus}
                />
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div className="form-group">
              <label className="control-label col-12 text-secondary small"
                     htmlFor={`${this.props.objectName}_${searchResultField}`}>
                {countLabel}
              </label>
              <div className="col-12">
                <select className="form-control form-control-sm"
                        name={`${this.props.objectName}[${searchResultField}]`}
                        id={`${this.props.objectName}_${searchResultField}`}>
                  {this.state.matchedRecords.map(
                    (r) => (
                      <option key={r[this.props.selectValueField]}
                              value={r[this.props.selectValueField]}>
                        {r[this.props.selectDisplayField]}
                      </option>
                    )
                  )}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SearchSelector;