import React from 'react';
import PropTypes from 'prop-types';
import SearchMultiselector from './search_multiselector';

class FilterOptions extends React.Component {
  static propTypes = {
    objectName: PropTypes.string.isRequired, // Object name to prefix form objects
    nestedObjectName: PropTypes.string, // Object name for optional nested attributes
    selectorField: PropTypes.string, // Field name for selector field indicating record type
    unfilteredLabel: PropTypes.string, // Label to use for unfiltered option
    filteredLabel: PropTypes.string, // Label to use for filtered option
    radioGroupLabel: PropTypes.string, // Label to use for group of record radios
    searchBoxAutoFocus: PropTypes.bool, // Whether or not to auto focus on search box in SearchMultiselects
    showMatchLabel: PropTypes.bool, // Whether or not to show the number of matches above select in SearchMultiselects
    showSelectedCount: PropTypes.bool, // Whether or not to show the number of records selected in SearchMultiselects
    idsPrefix: PropTypes.string, // Optional string to prefix element identifiers
    optionCollection: PropTypes.oneOfType(
      [PropTypes.array, PropTypes.arrayOf(PropTypes.object)]
    ).isRequired, // Collection of option objects to use to populate SearchMultisectors
  };

  constructor(props) {
    super(props);
    this.state = {
      filtered: false,
      activeRecordType: this.props.optionCollection[0]
    }
  }

  handleFilterClick = (e) => {
    this.setState({ filtered: e.target.value === 'filter' });
  };

  handleRecordTypeClick = (e) => {
    const newActiveRecordType = this.props.optionCollection.find(o => o.optionSelectorValue === e.target.value);
    this.setState({activeRecordType: newActiveRecordType});
  };

  render () {
    let allLabel = this.props.unfilteredLabel || 'All';
    let filteredLabel = this.props.filteredLabel || 'Filtered';
    let selectorFieldName = this.props.selectorField || 'selector';
    let selectorValue = '';
    let recordTypeBlock = '';
    let multiselector = '';
    let useShortSearch = this.state.activeRecordType.shortSearch || false;
    let nestedId = '';
    let nestedName = '';

    if (this.props.nestedObjectName) {
      nestedId = `_${this.props.nestedObjectName}`;
      nestedName = `[${this.props.nestedObjectName}]`;
    }

    const recordTypeOptions = (
      this.props.optionCollection.map(record_type => (
        <div className="radio radio-info form-check form-check-inline"
             key={record_type.optionSelectorValue}>
          <input type="radio"
                 id={`${this.props.idsPrefix}${record_type.optionSelectorValue}Record`}
                 value={record_type.optionSelectorValue}
                 onChange={this.handleRecordTypeClick}
                 checked={this.state.activeRecordType.optionSelectorValue === record_type.optionSelectorValue}
                 name="recordTypes" />
          <label htmlFor={`${this.props.idsPrefix}${record_type.optionSelectorValue}Record`}>{record_type.optionLabel}</label>
        </div>
      ))
    );

    if (this.state.filtered) {
      selectorValue = this.state.activeRecordType.optionSelectorValue;
      recordTypeBlock = (
        <div className="record-type-block">
          <div className="row mt-4">
            <div className="col-12">
              <label className="col-12 pl-0">Filter by</label>
              {recordTypeOptions}
            </div>
          </div>
          <hr />
        </div>
      );
      multiselector = (
        <SearchMultiselector
          apiSearchPath={this.state.activeRecordType.apiSearchPath}
          objectName={this.props.objectName}
          nestedObjectName={this.props.nestedObjectName}
          selectValueField={this.state.activeRecordType.selectValueField}
          selectDisplayField={this.state.activeRecordType.selectDisplayField}
          searchBoxLabel={this.state.activeRecordType.searchBoxLabel}
          searchBoxPlaceholder={this.state.activeRecordType.searchBoxPlaceholder}
          searchBoxAutoFocus={this.props.searchBoxAutoFocus}
          showMatchLabel={this.props.showMatchLabel}
          showSelectedCount={this.props.showSelectedCount}
          shortSearch={useShortSearch}
          idsCollectionName={this.state.activeRecordType.idsCollectionName} />
      );
    } else {
      selectorValue = 'all';
    }


    return (
      <div className="filter-options">
        <input type="hidden"
               name={`${this.props.objectName}${nestedName}[${selectorFieldName}]`}
               id={`${this.props.objectName}${nestedId}_${selectorFieldName}`}
               value={selectorValue} />
        <div className="row mt-4">
          <div className="col-12">
            <div className="radio radio-info form-check form-check-inline">
              <input type="radio"
                     id={`${this.props.idsPrefix}OptionAll`}
                     value="all"
                     name={`${this.props.idsPrefix}OptionFilterType`}
                     onChange={this.handleFilterClick}
                     checked={!this.state.filtered} />
              <label htmlFor={`${this.props.idsPrefix}OptionAll`}>{allLabel}</label>
            </div>
            <div className="radio radio-info form-check form-check-inline">
              <input type="radio"
                     id={`${this.props.idsPrefix}OptionFiltered`}
                     value="filter"
                     name={`${this.props.idsPrefix}OptionFilterType`}
                     onChange={this.handleFilterClick}
                     checked={this.state.filtered} />
              <label htmlFor={`${this.props.idsPrefix}OptionFiltered`}>{filteredLabel}</label>
            </div>
          </div>
        </div>
        {recordTypeBlock}
        {multiselector}
        <hr />
      </div>
    );
  }
}

export default FilterOptions;