import React from 'react';
import PropTypes from 'prop-types';

class SubscriptionDay extends React.Component {
  static propTypes = {
    dayOfWeek: PropTypes.string.isRequired, // String for day of week
    lastChanged: PropTypes.object.isRequired, // Object of most recent update for copying rows
    onDataChange: PropTypes.func.isRequired, // Function to call upon making changes
    isActive: PropTypes.bool, // Bool for marking check box for active or not
    mealCount: PropTypes.number, // Current meal count
    startTime: PropTypes.string, // Current program start time
    deliveryTime: PropTypes.string, // Current requested delivery time
    endTime: PropTypes.string, // Current program end time
  };

  constructor(props) {
    super(props);
    this.state = {
      isActive: this.props.isActive || false,
      fields: {
        mealCount: this.props.mealCount || '',
        startTime: this.props.startTime ? moment(this.props.startTime).format('HH:mm') : '',
        deliveryTime: this.props.deliveryTime ? moment(this.props.deliveryTime).format('HH:mm') : '',
        endTime: this.props.endTime ? moment(this.props.endTime).format('HH:mm') : ''
      }
    }
  }

  onInputChange = (e) => {
    const fields = Object.assign({}, this.state.fields);
    const fieldName = e.target.getAttribute("data-field-name");
    fields[fieldName] = e.target.value;
    this.setState({fields});
    this.props.onDataChange(e);
  };

  handleActiveClick = (e) => {
    this.setState({isActive: e.target.checked}, () => {
        document.getElementById(`mealCount${this.props.dayOfWeek}`).focus();
    });

    if (!e.target.checked) {
      this.setState({fields: {mealCount: '', startTime: '', deliveryTime: '', endTime: ''}});
    } else {
      this.setState({
        fields: {
          mealCount: this.props.lastChanged.mealCount,
          startTime: this.props.lastChanged.startTime,
          deliveryTime: this.props.lastChanged.deliveryTime,
          endTime: this.props.lastChanged.endTime
        }});
    }
  };


  render() {
    return (
      <tr>
        <td>
          <div className="checkbox m-0 mr-auto">
            <input type="checkbox"
                   checked={this.state.isActive}
                   onChange={this.handleActiveClick}
                   id={`day${this.props.dayOfWeek}`} />
            <label className="checkbox m-0 mr-auto" style={{textTransform: 'capitalize'}}
                   htmlFor={`day${this.props.dayOfWeek}`}>
              { this.props.dayOfWeek }
            </label>
          </div>
        </td>
        <td>
          <input className="form-control form-control-sm"
                 type="text"
                 disabled={!this.state.isActive}
                 name={`subscription[subscription_details][${this.props.dayOfWeek}][meal_count]`}
                 id={`mealCount${this.props.dayOfWeek}`}
                 value={this.state.fields.mealCount}
                 data-field-name="mealCount"
                 onChange={this.onInputChange}
          />
        </td>
        <td>
          <input className="form-control form-control-sm"
                 type="time"
                 disabled={!this.state.isActive}
                 name={`subscription[subscription_details][${this.props.dayOfWeek}][delivery_time]]`}
                 id={`deliveryTime${this.props.dayOfWeek}`}
                 value={this.state.fields.deliveryTime}
                 data-field-name="deliveryTime"
                 onChange={this.onInputChange}
                 placeholder="HH:MM AM/PM"
          />
        </td>
        <td>
          <input className="form-control form-control-sm"
                 type="time"
                 disabled={!this.state.isActive}
                 name={`subscription[subscription_details][${this.props.dayOfWeek}][start_time]]`}
                 id={`startTime${this.props.dayOfWeek}`}
                 value={this.state.fields.startTime}
                 data-field-name="startTime"
                 onChange={this.onInputChange}
                 placeholder="HH:MM AM/PM"
          />
        </td>
        <td>
          <input className="form-control form-control-sm"
                 type="time"
                 disabled={!this.state.isActive}
                 name={`subscription[subscription_details][${this.props.dayOfWeek}][end_time]]`}
                 id={`endTime${this.props.dayOfWeek}`}
                 value={this.state.fields.endTime}
                 data-field-name="endTime"
                 onChange={this.onInputChange}
                 placeholder="HH:MM AM/PM"
          />
        </td>
      </tr>
    );
  }

}

export default SubscriptionDay;